<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link
        :to="{ name: 'Home' }"
        class="brand-logo d-flex justify-content-center align-items-center"
      >
        <img :src="this.appLogoImage" width="75" height="75" />
        <div class="d-none d-lg-block">
          <h1 class="text-white m-0 ml-1 display-5 font-weight-bolder">
            {{ this.appFullName }}
          </h1>
        </div>
        <div class="d-lg-none">
          <h2 class="brand-text text-primary m-0 ml-1">
            {{ this.appFullName }}
          </h2>
        </div>
      </b-link>

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="overlay"></div>
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center"
        ></div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto mt-5 mt-lg-0">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-5 mt-lg-0"
          >
            Welcome to
            <span class="font-weight-bolder text-primary"
              >{{ this.appFullName }}!</span
            >
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <validation-observer ref="loginFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label-for="name">
                Name <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="{ required }"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    v-restrict="regex"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label-for="email">
                <template #label>
                  Email Address <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Email Address"
                  :rules="{ required }"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group label-for="cnic">
                NIN (Social Sequrity No)
                <validation-provider
                  #default="{ errors }"
                  name="Cnic"
                  rules="integer"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    name="cnic"
                    placeholder="CNIC"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-form-group label-for="mobilenumber">
                Mobile Number <span class="text-danger">*</span>
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Mobilenumber"
                  rules="required"
                > -->
                <VuePhoneNumberInput
                  v-model="mobileNo"
                  @update="onVuePhoneNumberUpdate"
                  :required="true"
                  color="#06bd46"
                  valid-color="#06bd46"
                  error-color="#ea5455"
                  :error="
                    mobileNoData !== null ? !mobileNoData['isValid'] : false
                  "
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
              <b-form-group label="Passport Number" label-for="passportnumber">
                <validation-provider
                  #default="{ errors }"
                  name="Passportnumber"
                  rules=""
                >
                  <b-form-input
                    id="passportnumber"
                    v-model="passportNo"
                    :state="errors.length > 0 ? false : null"
                    name="passportnumber"
                    placeholder="Passport Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nationality" label-for="nationality">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  :rules="{ regex: /^[A-Z\s]*$/i }"
                >
                  <b-form-input
                    id="Nationality"
                    v-model="nationality"
                    :state="errors.length > 0 ? false : null"
                    name="nationality"
                    placeholder="Nationality"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="password">
                Password <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  :rules="{
                    required: true,
                    regex:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <span :class="noteClass(errors)" style="font-size: 12px">
                    {{ noteText(errors) }}
                  </span>
                </validation-provider>
              </b-form-group>
              <validation-provider #default="{ errors }" name="Image">
                <b-form-group
                  class="form-group"
                  id="input-group-2"
                  label="Image:"
                  label-for="input-2"
                  description="Upload Image not more than 150kb size."
                >
                  <b-form-file
                    id="documents"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".png"
                    plain
                    v-model="files"
                  ></b-form-file>

                  <p class="error">{{ errors[0] }}</p>
                </b-form-group>
              </validation-provider>

              <b-button
                type="submit"
                variant="success"
                class=""
                block
                @click="validateForm"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>
          <div class="text-center">
            <b-link :to="{ name: 'Login' }">
              <p class="py-2 cursor-pointer">
                Already have account? Please
                <span class="font-weight-bolder text-colorGreen">
                  <u>Sign in </u>
                </span>
              </p>
            </b-link>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    VuePhoneNumberInput,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      name: "",
      email: "",
      dob: "",
      cnic: "",
      mobileNo: "",
      mobileNoData: null,
      passportNo: "",
      nationality: "",
      password: "",
      image: null,
      files: [],
      required,
      regex: /^[a-zA-Z ]*$/,
    };
  },

  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  computed: {},
  methods: {
    ...mapActions({ patientSignUp: "appData/patientSignUp" }),
    async validateForm() {
      const success = await this.$refs.loginFormValidation.validate();
      if (success && this.mobileNoData["isValid"]) {
        await this.submit();
      }
    },
    noteClass(errors) {
      return errors.length === 0 && this.password.length > 0
        ? "font-weight-normal text-success"
        : "font-weight-normal text-danger";
    },
    noteText(errors) {
      if (this.password.length === 0) {
        return "";
      }
      return errors.length === 0
        ? "Perfect Passowrd."
        : "The password must be at least 8 characters long, at least one lowercase letter, at least one uppercase letter, at least one digit and at least one special character (e.g., @$!%*?&).";
    },
    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },

    async submit() {
      try {
        let formattedMobileNo = this.mobileNoData[
          "formatInternational"
        ].replace(/[\s()+-]/g, "");
        const formData = new FormData();
        formData.append("full_name", this.name);
        formData.append("email", this.email);
        formData.append("dob", this.dob);
        formData.append("cnic", this.cnic);
        formData.append("mobile", formattedMobileNo);
        formData.append("password", this.password);
        formData.append("passport_number", this.passportNo);
        formData.append("nationality", this.nationality);
        formData.append("profile_image", this.files);

        const res = await this.patientSignUp(formData);
        if (res.status === 200) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Sign up successfully",
                variant: "success",
                icon: "BellIcon",
              },
            },
            {
              position: "top-right",
            }
          );
          this.reset();
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.email = "";
      this.dob = "";
      this.cnic = "";
      this.mobileNo = "";
      this.passportNo = "";
      this.nationality = "";
      this.password = "";
      this.image = null;
      this.files = [];
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.overlay {
  background: rgba(255, 255, 255, 0.7) url("~@/assets/images/slider/login.png");
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
</style>
