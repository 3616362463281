var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo d-flex justify-content-center align-items-center",attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":this.appLogoImage,"width":"75","height":"75"}}),_c('div',{staticClass:"d-none d-lg-block"},[_c('h1',{staticClass:"text-white m-0 ml-1 display-5 font-weight-bolder"},[_vm._v(" "+_vm._s(this.appFullName)+" ")])]),_c('div',{staticClass:"d-lg-none"},[_c('h2',{staticClass:"brand-text text-primary m-0 ml-1"},[_vm._v(" "+_vm._s(this.appFullName)+" ")])])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center"})]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto mt-5 mt-lg-0",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 mt-5 mt-lg-0",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to "),_c('span',{staticClass:"font-weight-bolder text-primary"},[_vm._v(_vm._s(this.appFullName)+"!")])]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please sign-in to your account ")]),_c('validation-observer',{ref:"loginFormValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"name","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"restrict",rawName:"v-restrict",value:(_vm.regex),expression:"regex"}],attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email Address","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Email Address"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"mobilenumber"}},[_vm._v(" Mobile Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('VuePhoneNumberInput',{attrs:{"required":true,"color":"#06bd46","valid-color":"#06bd46","error-color":"#ea5455","error":_vm.mobileNoData !== null ? !_vm.mobileNoData['isValid'] : false},on:{"update":_vm.onVuePhoneNumberUpdate},model:{value:(_vm.mobileNo),callback:function ($$v) {_vm.mobileNo=$$v},expression:"mobileNo"}})],1),_c('b-form-group',{attrs:{"label":"Passport Number","label-for":"passportnumber"}},[_c('validation-provider',{attrs:{"name":"Passportnumber","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"passportnumber","state":errors.length > 0 ? false : null,"name":"passportnumber","placeholder":"Passport Number"},model:{value:(_vm.passportNo),callback:function ($$v) {_vm.passportNo=$$v},expression:"passportNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nationality","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":{ regex: /^[A-Z\s]*$/i }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Nationality","state":errors.length > 0 ? false : null,"name":"nationality","placeholder":"Nationality"},model:{value:(_vm.nationality),callback:function ($$v) {_vm.nationality=$$v},expression:"nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password"}},[_vm._v(" Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Password","rules":{
                  required: true,
                  regex:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{class:_vm.noteClass(errors),staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.noteText(errors))+" ")])]}}])})],1),_c('validation-provider',{attrs:{"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"form-group",attrs:{"id":"input-group-2","label":"Image:","label-for":"input-2","description":"Upload Image not more than 150kb size."}},[_c('b-form-file',{attrs:{"id":"documents","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":".png","plain":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-button',{attrs:{"type":"submit","variant":"success","block":""},on:{"click":_vm.validateForm}},[_vm._v(" Sign up ")])],1)],1),_c('div',{staticClass:"text-center"},[_c('b-link',{attrs:{"to":{ name: 'Login' }}},[_c('p',{staticClass:"py-2 cursor-pointer"},[_vm._v(" Already have account? Please "),_c('span',{staticClass:"font-weight-bolder text-colorGreen"},[_c('u',[_vm._v("Sign in ")])])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }